import * as React from "react"
import PortfolioTilesOuter from "../../components/portfolioTilesOuter"
import { graphql } from "gatsby"

const CommercialsPage = (props) => {
    const nodes = props.data.contentfulPortfolioOrder.order
    return (
        <PortfolioTilesOuter nodes={nodes} subfolder="/commercials/" />
    )
}

export default CommercialsPage

export const query = graphql`
query allCommercials {
  contentfulPortfolioOrder(category: {eq: "Commercial"}) {
    order {
      id
      title
      subtitle
      shouldShowSubtitle
      slug
      thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`

export const Head = () => {
    return (
        <>
            <title>COMMERCIALS - BO MIRHOSSENI</title>
        </>
    )
}